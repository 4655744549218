import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { MdChevronRight } from 'react-icons/md'

const LogDoors = () => {

  return (
    <Layout formTitle="Let us know how we can help with doors &amp; windows for you log home.">
      <Seo 
        title="Log Home Doors &amp; Windows"
        description="We work with the best brand name doors and windows on the market. The windows and doors in your home are one of the most important design considerations." 
      />
      <div className="container mx-auto pt-32 md:pt-48">
        <div className="relative flex items-center justify-center bg-header overflow-hidden">
          <h1 className="text-white px-6 py-32 md:px-12 md:py-64">Log Home Windows &amp; Doors</h1>
          <StaticImage
            src="../images/log_home_doors_header.jpg"
            alt="a custom log home in Vermont" 
            placeholder="blurred"
            className="header-bg-img"
          />
        </div>
      </div>
      <div className="container mx-auto mt-16 md:mt-32">
        <h2>Log Types</h2>
        <p className="max-w-3xl mb-12">We work with the best brand name doors and windows on the market. The windows and doors in your home are one of the most important design considerations; both in terms of appearance and efficiency. We will help you design an effective and efficient window and door layout that works with your lifestlye and budget.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
          <div>
            <StaticImage
              src="../images/pella_logo.jpg"
              alt="Pella Logo"
              placeholder="blurred"
              quality={100}
              width={100}
            />
            <h3 className="mt-6">Windows &amp; Patio Doors by Pella®</h3>
            <p className="mb-4">We work directly with Pella to get you the best windows and doors at the best price. We have an extencive working relationship with Pella and can offer you more customised options and window designs because of this relationship.</p>
            <a href="https://www.pella.com/" target="_blank" rel="noreferrer noopener" className="secondary-btn">Visit Pella<span><MdChevronRight /></span></a>
          </div>
          <div>
            <StaticImage
              src="../images/velux_logo.jpg"
              alt="Velux Logo"
              placeholder="blurred"
              quality={100}
              width={100}
            />
            <h3 className="mt-6">Skylights by Velux®</h3>
            <p className="mb-4">When it comes to skylights Velux is the best option.</p>
            <a href="https://www.velux.com/" target="_blank" rel="noreferrer noopener" className="secondary-btn">Visit Velux<span><MdChevronRight /></span></a>
          </div>
        </div>
        <hr className="my-24 md:my-36" />
      </div>
    </Layout>
  );
}
 
export default LogDoors;